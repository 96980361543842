<template>
    <v-card style="margin:-20px;">
        <div class="profile-tab-container" v-if="Object.keys(selected_student).length > 0">
            <div class="profile-intro">
                <h2>{{selected_student.full_name}} <span>({{selected_student.academic.enroll_code}})</span></h2>
                <h4>Grade {{selected_student.academic.grade}} / {{selected_student.academic.section}}</h4>
            </div>

            <div class="tab-content">
                <div class="t-container">
                    <div class="profile-image">
                        <div :class="'image'">
                            <img :src="selected_student.profile_image"
                                 alt="User Image">
                            <input type="file"
                                   style="display: none"
                                   ref="image"
                                   accept="image/*"
                            >
                        </div>
                    </div>
                    <div class="t-hold">
                        <v-bottom-nav
                                :active.sync="activeBtn"
                                :value="true"
                                absolute
                                color="transparent">
                            <v-btn color="teal"
                                   flat
                                   value="general">
                                <span>{{label}}</span>
                                <v-icon>history</v-icon>
                            </v-btn>
                        </v-bottom-nav>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="profile-tab-container">
            <div class="tab-content">
                <div class="t-container">

                    <div class="t-hold">
                        <v-bottom-nav
                                :active.sync="activeBtn"
                                :value="true"
                                absolute
                                color="transparent">
                            <v-btn color="teal"
                                   flat
                                   value="general">
                                <span>Select Child</span>
                                <v-icon>history</v-icon>
                            </v-btn>
                        </v-bottom-nav>
                    </div>
                </div>
            </div>

        </div>
    </v-card>
</template>
<script>
    import {mapState, mapActions, mapMutations} from 'vuex'
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
        },
        data: () => ({
            activeBtn: 'general',
        }),
        watch: {
        },
        mounted() {
        },
        computed: {
            ...mapState(['selected_student'])
        },
        methods: {

        }
    }
</script>

<style lang="scss">
</style>
