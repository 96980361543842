<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <student-info :label="'Exam Routine'"></student-info>
            </v-flex>
            <div class="profile-contents">
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-card class="page-container-card">
                            <v-card>
                                <v-card-title class="title">
                                    <v-flex xs4>
                                        <v-select :items="exams" class="pa-0" label="Exam" v-model="exam_id"/>
                                    </v-flex>
                                    <search-button :disabled="!exam_id" permission="receipt-read"
                                                   @action="get()">
                                        Search
                                    </search-button>
                                </v-card-title>
                            </v-card>
                        <v-data-table
                                :headers="headers"
                                hide-default-footer
                                :options.sync="pagination"
                                :items="form.items.data"
                                :loading="form.loading">
                            <template  v-slot:item="{index, item}">
                                <tr>
                                    <td>{{ index + form.items.meta.from }}</td>
                                    <td class="text-xs-left">
                                        <strong>{{ item.subject.name | capitalize }}</strong> <br>
                                        <small style="color:#555">
                                        <span style="color:red;"
                                            v-if="/शनिबार/.test(getDateFormat(form.items.data[index].exam_date))">
                                            {{getDateFormat(form.items.data[index].exam_date)}}
                                        </span>
                                            <span v-else>
                                                {{getDateFormat(form.items.data[index].exam_date)}}
                                        </span>
                                        </small>
                                    </td>
    <!--                                <td class="text-xs-left">-->
    <!--                                    {{form.items.data[index].exam_date}}-->
    <!--                                </td>-->
                                    <td class="text-xs-center">
                                        {{form.items.data[index].start_time}}
                                    </td>
                                    <td class="text-xs-center">
                                        {{form.items.data[index].end_time}}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        </v-card>
                    </v-flex>
                </v-layout>
            </div>
        </v-layout>

    </v-container>
</template>
<script>
    import Form from '@/library/Form'
    import {mapState, mapActions, mapMutations} from 'vuex'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    import StudentInfo from "../studentInfo";
    import {filterObjectFromArray, getThForm} from "@/library/helpers";


    export default {
        // ProfileHome, ProfileEnroll, ProfileTransport,
        components: {StudentInfo},
        data: () => ({
            form: new Form({}, '/api/exam-routine'),
            periodCount: 0,
            pagination: {
                rowsPerPage: 200
            },
            exam_id:'',
            exams: [],
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false, width: 400},
                // {text: 'Exam Date', align: 'centre', value: 'exam_date', width: 180, sortable: false},
                {text: 'Exam Start Time', align: 'centre', value: 'end_time', sortable: false},
                {text: 'Exam End Time', align: 'centre', value: 'end_time', sortable: false},
            ],


        }),
        watch: {
            'batch': {
                handler() {
                    this.get();
                }
            },
            'selected_student': {
                handler() {
                    this.get();
                }
            }
        },
        mounted() {
            this.getExams();
            // this.get();
        },
        computed: {
            ...mapState(['student', 'batch', 'selected_student'])
        },
        methods: {
            filterObjectFromArray, getThForm,
            ...mapActions(['setStudent', 'getStudentInfo']),

            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&batchId=' + this.batch.id + '&gradeId=' + this.selected_student.academic.grade_id + '&examId=' + this.exam_id;
            },
            get(params) {
                if (this.exam_id && Object.keys(this.selected_student).length > 0 ) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        this.is_publish = data.isPublished;
                        let d = [];

                        d = this.form.items.data.map(res => {
                            if (!res.st_ampm) {
                                res.st_ampm = 'AM';
                            }
                            if (!res.et_ampm) {
                                res.et_ampm = 'PM';
                            }
                            if (!res.exam_date) {
                                res.exam_date = nd.format('YYYY-MM-')
                            }
                        });
                    })
                }
            },
            getExams() {
                this.$rest.get('/api/exam').then(({data}) => {
                    this.exams = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            getDateFormat(date) {
                try {
                    let dateInstance = new NepaliDate(date.dateForm());
                    return dateInstance.format('mmmm d, yyyy dddd');
                } catch (e) {
                    // this.updateState = false;
                    return '-';
                }
            },
        },

    }
</script>
<style lang="scss" scoped>
    .profile-contents {
        margin-top: 30px;
    }
    .inputTime {
        border: solid 1px #777;
        width: 65px;
        text-align: center;
        height: 32px;
    }

    .teacherInfo {
        position: absolute;
        margin-top: -21px;
        font-size: 12px;
        color: #777;
    }

    .inputTime:disabled {
        cursor: not-allowed;
        background: #99999921;
    }

    .theme--light.v-table tbody td {
        border: 1px solid rgba(0, 0, 0, .22);
    }

    .cellHover {
        cursor: pointer;
    }

    table td div p {
        padding-bottom: 0px;
        margin-bottom: 7px;
    }

    table td div {
        margin-top: 5px;
    }

    table td div:nth-child(2n+1) {
        border-bottom: 1px solid #ccc;
    }

    table td div:last-child {
        border-bottom: none;
    }
</style>